import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  category?: string
  description?: string
  image?: string
  title: string
}

export const Experience = memo(function Experience({
  category,
  description,
  image,
  title,
}: Props) {
  return (
    <Container>
      <Item>
        {image ? (
          <LazyLoad>
            <BackgroundImage style={{ backgroundImage: `url(${image})` }} />
            <ImageWrap>
              <Image style={{ backgroundImage: `url(${image})` }} />
            </ImageWrap>
          </LazyLoad>
        ) : null}
        <TextWrap dial={5}>
          {category ? <Category>{category}</Category> : null}
          <Title>{title}</Title>
        </TextWrap>
      </Item>
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.div``

const ImageWrap = styled.span`
  width: calc(100% - 2.5rem);
  height: calc(100% - 5rem);
  overflow: hidden;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-out;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 1.5rem);
    height: calc(100% - 3rem);
    top: 1.5rem;
    left: 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: 0.3s ease-out;
`

const BackgroundImage = styled.span`
  width: 56%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-out;

  @media (max-width: 767px) {
    display: none;
  }
`

const Item = styled.span`
  display: block;
  height: 100%;
  padding-bottom: 82%;
  position: relative;

  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
`

const TextWrap = styled(FlexBox)`
  width: calc(100% - 2.5rem);
  height: calc(100% - 5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 6rem 1.5rem;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 2;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding: 0 1.5rem;
    top: 0;
    left: 0;
  }
`

const Category = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 1px;
  line-height: 0.875rem;
  opacity: 0.4;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 1.875rem;
  p {
    margin: 0;
  }

  @media (max-width: 767px) {
    margin-top: 0;
    padding: 2.25rem 1.5rem 0;
  }
`
