import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Experience, Props as ExperienceProps } from './Experience'

export interface Props {
  experiences?: ExperienceProps[]
}

export const ExperiencesList = memo(function ExperiencesList({
  experiences,
}: Props) {
  if (!experiences) {
    return null
  }

  return (
    <Container>
      <List row wrap space="between">
        {experiences.map((item, index) => (
          <FadeInOutAnimation key={index}>
            <Experience {...item} />
          </FadeInOutAnimation>
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin-bottom: 5.25rem;
`

const List = styled(FlexBox)`
  max-width: 73.5rem;
  margin: auto;
  padding: 0 1.5rem;
  > div {
    width: calc(50% - 3rem);
    margin-bottom: 5.25rem;
  }

  @media (max-width: 767px) {
    padding: 0;
    > div {
      width: 100%;
      margin-bottom: 3.75rem;
    }
  }
`
